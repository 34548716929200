import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container } from '../../../ui/common';
import { Section, Image, Title, Text, Link } from './styled';
import envelop from './envelop.svg';

function Email() {
  return (
    <Section>
      <Container>
        <Image>
          <img src={envelop} alt="evapolar" />
        </Image>
        <Title>
          <FormattedMessage id="affiliate.email.title" />
        </Title>
        <Text>
          <FormattedMessage id="affiliate.email.text" />
        </Text>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link herf="mailto:alena@evapolar.com">alena@evapolar.com</Link>
      </Container>
    </Section>
  );
}

export default Email;
