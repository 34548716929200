import styled, { css } from 'styled-components';
import { colors } from '../../../ui/common';

export const fonts = {
  neueMachina: {
    name: `'Neue Machina', sans-serif`,
    weight: {
      light: 300,
      regular: 400,
      ultrabold: 800,
    },
  },
  montserrat: {
    name: `'Montserrat', sans-serif`,
    weight: {
      300: 300,
      400: 400,
      500: 500,
      600: 600,
    },
  },
};

export const sizes = {
  fullHD: 1920,
  desktopLarge: 1440,
  desktop: 1400,
  laptop: 1200,
  medium: 960,
  tablet: 768,
  phone690: 691,
  phone: 600,
};

export const sizesInvert = {
  fullHD: 1919,
  desktopLarge: 1439,
  desktop: 1399,
  laptop: 1199,
  medium: 959,
  tablet: 767,
  phone690: 690,
  phone: 599,
};

export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // const emSize = sizes[label] / 16;
  accumulator[label] = (...args) => `
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export const mediaInv = Object.keys(sizesInvert).reduce(
  (accumulator, label) => {
    // const emSize = sizes[label] / 16;
    accumulator[label] = (...args) => `
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
    return accumulator;
  },
  {}
);

export const text = css`
  font-weight: 300;
  font-size: 13px;
  color: ${colors.color01};
`;

export const Text = styled.div`
  ${text};
  line-height: 1.75;
`;

export const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  ${media.medium`
    max-width: 930px;
  `}
  ${media.laptop`
    max-width: 1140px;
  `}
`;

export const ContBig = styled.section`
  max-width: 638px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  ${media.medium`
    max-width: 930px;
  `}
  ${media.laptop`
    max-width: 1310px;
  `}
`;

export const ContBigWhy = styled.section`
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  ${media.medium`
    max-width: 930px;
    padding: 0 15px;
  `}
  ${media.laptop`
    max-width: 1310px;
  `}
`;

export const BlueLink = styled.div`
  background-color: ${colors.color18};
  border-radius: 500px;
  color: white;
  padding: 8px 16px;
  max-width: fit-content;
  font-family: 'HelveticaNeueCyr';
  font-size: 18px;
  margin: 24px auto 0 auto;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    background-color: #434db3;
  }
  ${media.tablet`

  `}
`;

export const Headline = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.5;
  text-align: center;
  color: ${colors.color05};
  ${media.phone`
    font-size: 38px;
  `}
`;

export const HeadlineNew = styled.h2`
  font-family: 'Craftwork Grotesk';
  margin: 0;
  font-size: 28px !important;
  line-height: 100%;
  text-align: left !important;
  color: ${colors.color19};
  ${media.phone`
    font-size: 56px !important;
  `}
`;

export const Products = styled.div`
  padding: 16px 0;
  margin: 0 -10px;

  ${media.phone`
    padding: 25px 0;
    display: flex;
    justify-content: center;
  `}
`;
