import styled from 'styled-components';
import { media } from '../ui/common';
import { colors } from '../../../ui/common';

export const Section = styled.section`
  ${media.tablet`
    position: relative;
  `}
`;

export const Wrap = styled.div`
  & > div {
    min-height: 250px;
  }
  ${media.tablet`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    & > div{ 
      height: 100%;
      min-height: none;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
  `}
`;

export const Inner = styled.div`
  padding: 24px 0;
  box-sizing: border-box;
  ${media.tablet`
    position: relative;
    min-height: 420px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 510px;
    max-width: 480px;
  `}
  ${media.medium`
    max-width: 48%;
    min-height: 640px;
  `}
  ${media.laptop`
    min-height: 800px;
  `};
  @media (min-width: 960px) {
    margin-left: ${(props) => (props.isRight ? 'auto' : 0)};
  }
`;

export const Title = styled.h2`
  color: ${colors.color05};
  font-weight: 600;
  font-size: 28px;
  line-height: 1.5;
  margin: 0 0 12px;
  ${media.tablet`
    color: white;
    font-size: 32px;
    margin: 0 0 18px;
  `}
  ${media.laptop`
    font-size: 38px;
    margin: 0 0 28px;
  `}
`;

export const Text = styled.p`
  color: ${colors.color01};
  font-weight: 300;
  font-size: 16px;
  line-height: 1.95;
  margin: 0;
  ${media.tablet`
    color: white;
    line-height: 1.85;
  `}
  ${media.laptop`
    margin-bottom: 32px;
  `}
`;
