import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container } from '../ui/common';
import { Section, Wrap, Inner, Title, Text } from './styled';

function Promo({ image, title, text, isRight }) {
  return (
    <Section>
      <Wrap>
        <GatsbyImage image={image} alt="" />
      </Wrap>
      <Container>
        <Inner isRight={isRight}>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </Inner>
      </Container>
    </Section>
  );
}

export default Promo;

Promo.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.node,
  text: PropTypes.node,
  isRight: PropTypes.bool,
};

Promo.defaultProps = {
  image: {},
  title: null,
  text: null,
  isRight: false,
};
