import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { media } from '../../../ui/common';
import { primary } from '../../../ui/buttonDefault';

export const Section = styled(BackgroundImage)`
  overflow: hidden;
  margin-top: 80px;
  &:before {
    background-position: center right 40%;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
`;
export const Inner = styled.div`
  min-height: 576px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 0;
  box-sizing: border-box;
  color: #fff;
  ${media.phone`
     min-height: 630px;
     padding: 0 0 42px;
  `}
  ${media.laptop`
     min-height: 658px;
  `}
`;
export const Title = styled.h1`
  font-weight: 500;
  font-size: 32px;
  margin: 0 0 18px;
  line-height: 1.25;
  max-width: 500px;
  ${media.phone`
    font-size: 48px;
    margin-bottom: 30px;
  `}
  ${media.laptop`
    font-size: 52px;
    margin-bottom: 20px;
  `}
`;
export const Text = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 1.75;
  max-width: 250px;
  margin: 0 0 30px;
  ${media.phone`
    max-width: 360px;
    margin-bottom: 38px;
  `}
`;

export const Button = styled.button`
  ${primary};
  width: auto;
  height: 70px;
  margin-bottom: 10px;
`;
