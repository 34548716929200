import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container } from '../../../ui/common';
import { Section, List, Block, Image, Title, Text } from './styled';
import list from './data';

function Steps() {
  return (
    <Section>
      <Container>
        <List>
          {list.map((el) => (
            <Block key={el.id}>
              <Image>
                <img src={el.image} alt="evapolar" />
              </Image>
              <Title>
                <FormattedMessage id={el.title} />
              </Title>
              <Text>
                <FormattedMessage id={el.text} />
              </Text>
            </Block>
          ))}
        </List>
      </Container>
    </Section>
  );
}

export default Steps;
