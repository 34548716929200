import styled from 'styled-components';
import { media, colors } from '../../../ui/common';

export const Section = styled.section`
  padding: 80px 0;
  text-align: center;
  background: rgba(204, 226, 226, 0.15);
  ${media.phone`
    padding: 120px 0;
  `}
  ${media.laptop`
    padding: 140px 0;
  `}
`;
export const Image = styled.div`
  margin-bottom: 35px;
`;
export const Title = styled.h2`
  font-weight: 600;
  font-size: 28px;
  line-height: 1.75;
  color: ${colors.color05};
  opacity: 0.85;
  ${media.phone`
    font-size: 38px;
    margin: 12px 0;
  `}
`;
export const Text = styled.p`
  font-weight: 300;
  font-size: 16px;
  color: ${colors.color01};
  opacity: 0.9;
  line-height: 1.75;
  max-width: 430px;
  margin: auto;
`;
export const Link = styled.a`
  font-weight: 500;
  font-size: 16px;
  color: ${colors.color01};
  opacity: 0.9;
  margin-top: 32px;
  display: block;
  ${media.phone`
    margin-top: 36px;
  `}
`;
