import styled from 'styled-components';
import { media, colors } from '../../../ui/common';

export const Section = styled.section`
  padding: 65px 0;
  ${media.laptop`
    padding:  88px 0 60px;
  `}
`;
export const List = styled.div`
  ${media.phone`
    display: flex;
  `}
`;
export const Block = styled.div`
  text-align: center;
  padding: 35px 0;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  ${media.phone`
    max-width: 33%;
  `}
`;
export const Image = styled.div`
  margin-bottom: 45px;
`;
export const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  color: ${colors.color05};
  margin: 0 0 10px;
`;
export const Text = styled.p`
  font-weight: 300;
  font-size: 14px;
  color: ${colors.color01};
  opacity: 0.9;
  line-height: 1.75;
  margin: 0;
  padding: 0 10%;
`;
