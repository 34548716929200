import React, { useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import Hero from '../components/Affiliate/Hero';
import Steps from '../components/Affiliate/Steps';
import Promo from '../components/Products/Promo';
import Email from '../components/Affiliate/Email';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar';
import { mixpanelTrack } from '../utils/datalayer/dataLayer';

export const promo = graphql`
  {
    promo: file(relativePath: { eq: "affiliate/affiliate-promo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2880, quality: 100, layout: FULL_WIDTH)
      }
    }
    hero: file(relativePath: { eq: "affiliate/affiliate-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

function AffiliateProgramPage() {
  useEffect(() => mixpanelTrack('aff-program'));
  return (
    <StaticQuery
      query={promo}
      render={(data) => (
        <>
          <NavBar />
          <Hero image={data.hero.childImageSharp.fluid} />
          <Steps />
          <Promo
            isRight
            image={data.promo.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="affiliate.promo.title" />}
            text={
              <FormattedMessage
                id="affiliate.promo.text"
                values={{
                  // eslint-disable-next-line react/no-unstable-nested-components
                  p: (chunks) => <p>{chunks}</p>,
                }}
              />
            }
          />
          <Email />
          <Footer />
        </>
      )}
    />
  );
}

export default AffiliateProgramPage;
