import person from './person.svg';
import link from './link.svg';
import start from './start.svg';

export default [
  {
    id: 1,
    image: person,
    title: 'affiliate.step1.title',
    text: 'affiliate.step1.text',
  },
  {
    id: 2,
    image: link,
    title: 'affiliate.step2.title',
    text: 'affiliate.step2.text',
  },
  {
    id: 3,
    image: start,
    title: 'affiliate.step3.title',
    text: 'affiliate.step3.text',
  },
];
