import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Container } from '../../../ui/common';
import { Section, Inner, Title, Text, Button } from './styled';

const affiliateLink = process.env.GATSBY_AFFILIATE_LINK;

function Hero({ image }) {
  const handleClick = (e) => {
    e.preventDefault();
    window.location.href = affiliateLink;
  };

  return (
    <Section Tag="section" fluid={image}>
      <Container>
        <Inner>
          <Title>
            <FormattedMessage id="affiliate.hero.title" />
          </Title>
          <Text>
            <FormattedMessage id="affiliate.hero.text" />
          </Text>
          <Button onClick={handleClick}>
            <FormattedMessage id="affiliate.hero.button" />
          </Button>
        </Inner>
      </Container>
    </Section>
  );
}

export default Hero;

Hero.propTypes = {
  image: PropTypes.objectOf(PropTypes.any).isRequired,
};
